import React from 'react'
import Copy from 'components/Copy'
import Image from 'components/Image'
import Row from 'components/Row'
import Text from 'components/Text'
import GatedResource from 'templates/GatedResource'
import workoutImage from './images/workout.jpg'
import workout2xImage from './images/workout@2x.jpg'

export default function ResourceOnTheGoWorkout() {
  const title = 'On the go workout'

  return (
    <GatedResource
      title={
        <>
          Bold on the go:
          <br />A complete workout
        </>
      }
      entity="guide"
      resource={title}>
      <Row>
        <Copy>
          <Text element="p">On the go?</Text>
          <Text element="p">
            This Bold guide includes pictures and instructions on a full-body workout you can do
            anywhere, without any equipment.
          </Text>
        </Copy>
      </Row>
      <Image src={workoutImage} src2x={workout2xImage} alt="Bold workout" />
    </GatedResource>
  )
}
